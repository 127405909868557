<template>
  <section id="error_404" class="error_404">
    <a href="/">
      <svg class="logo_bayer" viewBox="0 0 76 76">
        <path
          class="logo_bayer__texto"
          d="M35.9 11.3h4.4c.5 0 .9-.4.9-.9 0-.5-.4-.9-.9-.9h-4.4V11.3zM35.9 15.5h4.5c.6 0 1-.4 1-1 0-.6-.4-1-1-1h-4.5V15.5zM43 12.3c.6.6 1 1.4 1 2.3 0 1.8-1.4 3.2-3.2 3.2h-7.3V7.3l7.2 0c1.7 0 3.1 1.4 3.1 3.1C43.7 11.1 43.4 11.8 43 12.3zM44.7 30.3H42l-.8-1.8h-5.9l-.8 1.8h-2.7L37 19.8h2.4L44.7 30.3zM38.2 22.5l-1.8 3.8H40L38.2 22.5zM41.8 32.6h3l-5.3 6.8v3.7h-2.5v-3.7l-5.3-6.8h3l3.6 4.8L41.8 32.6zM55.7 32.6v2.3h-7v1.8l6.8 0v2.3h-6.8v2h7v2.3h-9.5V32.6H55.7zM63.4 39.1h-1.9v4h-2.5V32.6h6.4c1.8 0 3.2 1.5 3.2 3.3 0 1.5-1 2.7-2.3 3.1l3.1 4.1h-3L63.4 39.1zM65.2 34.8h-3.6v2h3.6c.6 0 1-.5 1-1C66.2 35.3 65.7 34.8 65.2 34.8zM32.8 43.1h-2.7l-.8-1.8h-5.9l-.8 1.8h-2.7l5.3-10.5h2.4L32.8 43.1zM26.3 35.3l-1.8 3.8h3.7L26.3 35.3zM10.4 36.6h4.4c.5 0 .9-.4.9-.9 0-.5-.4-.9-.9-.9l-4.4 0V36.6zM10.4 40.8h4.5c.6 0 1-.4 1-1 0-.6-.4-1-1-1h-4.5V40.8zM17.5 37.6c.6.6 1 1.4 1 2.3 0 1.8-1.4 3.2-3.2 3.2H7.9V32.6h7.2c1.7 0 3.1 1.4 3.1 3.1C18.2 36.4 17.9 37.1 17.5 37.6zM43 45.3v2.3h-7v1.8l6.8 0v2.3h-6.8v2h7v2.3h-9.5V45.3H43zM41.2 61.6c0-.6-.4-1-1-1h-4.3v2h4.3C40.8 62.6 41.2 62.2 41.2 61.6zM33.4 68.9V58.4h7c1.8 0 3.2 1.5 3.2 3.3 0 1.4-.8 2.5-2 3l3.2 4.2h-3l-3-4h-2.9v4H33.4z"
        />
        <path
          class="logo_bayer__circulo--superios"
          d="M76.1 35.6C74.9 15.8 58.4 0 38.2 0 18 0 1.5 15.8.3 35.6c0 .8.1 1.6.2 2.4.8 6.6 3.3 12.7 7.1 17.8 6.9 9.4 18 15.5 30.6 15.5-17.6 0-32-13.7-33.2-30.9-.1-.8-.1-1.6-.1-2.4 0-.8 0-1.6.1-2.4C6.2 18.4 20.6 4.7 38.2 4.7c12.6 0 23.7 6.1 30.6 15.5 3.8 5.1 6.3 11.2 7.1 17.8.1.8.2 1.6.2 2.3 0-.8.1-1.6.1-2.4C76.2 37.2 76.2 36.4 76.1 35.6"
        />
        <path
          class="logo_bayer__circulo--inferior"
          d="M.3 40.4C1.5 60.2 18 76 38.2 76c20.2 0 36.7-15.8 37.9-35.6 0-.8-.1-1.6-.2-2.4-.8-6.6-3.3-12.7-7.1-17.8-6.9-9.4-18-15.5-30.6-15.5 17.6 0 32 13.7 33.2 30.9.1.8.1 1.6.1 2.4 0 .8 0 1.6-.1 2.4-1.2 17.3-15.6 30.9-33.2 30.9-12.6 0-23.7-6.1-30.6-15.5C3.8 50.7 1.3 44.6.5 38c-.1-.8-.2-1.6-.2-2.3 0 .8-.1 1.6-.1 2.4C.2 38.8.2 39.6.3 40.4"
        />
      </svg>
    </a>
    <p class="error_404__titulo">
      ¡VAYA! Parece que no podemos encontrar la página que buscas...
    </p>
    <p class="error_404__texto">
      Vuelve <a href="/" class="error_404__atras">atrás</a> o sigue uno de estos
      enlaces que te pueden interesar
    </p>
    <!-- <div class="contenedor">
      <a href="" class="contenedor__enlace">La Felguera</a>
      <a href="" class="contenedor__enlace">Adiro<span class="caracter_special">&#x00AE;</span></a>
      <a href="" class="contenedor__enlace">ePIL</a>
    </div> -->
  </section>
</template>

<script>
export default {
  name: "Error 404",
};
</script>
